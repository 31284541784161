import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@gameficame/ui-kit/src/components';
const statusTexts = {
    ACTIVE: 'SUCESSO',
    PROCESSING: 'PROCESSANDO',
    FAILED: 'ERRO',
    STORE_NOT_REGISTERED: 'LOJA NÃO CADASTRADA',
    DUPLICATE: 'ERRO',
    INVALID_CODE: 'ERRO',
};
export const statusColors = {
    ACTIVE: 'green',
    PROCESSING: 'yellow',
    STORE_NOT_REGISTERED: 'yellow',
    FAILED: 'red',
    DUPLICATE: 'red',
    INVALID_CODE: 'red',
};
export function BadgeStatus({ status }) {
    const { t } = useTranslation();
    const color = statusColors[status] || 'red';
    const textKey = statusTexts[status] || 'ERRO';
    return (_jsx(Badge, Object.assign({ "data-testid": "badge-status", badgeColor: color }, { children: t(textKey) })));
}

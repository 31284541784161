var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@gameficame/ui-kit/src/components';
import { GROUPS } from '~/layouts/constants';
import { useMe } from '~/layouts/hooks/useMe';
import { SidebarButton } from './SidebarButton';
import * as Styled from './styles';
function Sidebar(_a) {
    var rest = __rest(_a, []);
    const { data } = useMe();
    const { t } = useTranslation();
    const adminLinks = [
        {
            to: '/admin/panel',
            title: t('sidebar.option.panel'),
        },
        {
            to: '',
            title: t('sidebar.option.mechanics'),
            subBtn: [
                {
                    to: '/admin/item-shop',
                    title: t('sidebar.subOption.mechanics.itemShop'),
                    soon: false,
                },
                {
                    to: '/admin/member',
                    title: t('sidebar.subOption.mechanics.inviteFriends'),
                    soon: false,
                },
                {
                    to: '/admin/vouchers',
                    title: 'Vouchers',
                    soon: false,
                },
                {
                    to: '/admin/nfes',
                    title: 'Notas Fiscais',
                    soon: false,
                },
                { to: '', title: 'Air Drop', soon: true },
                {
                    to: '',
                    title: t('sidebar.subOption.mechanics.dailyReward'),
                    soon: true,
                },
            ],
        },
        {
            to: '',
            title: 'SORTEIOS',
            subBtn: [
                {
                    to: '/admin/new-raffle',
                    title: 'Novo Sorteio',
                    soon: false,
                },
                {
                    to: '/admin/raffle/check-winner',
                    title: 'Consultar Ganhador',
                    soon: false,
                },
                {
                    to: '/admin/raffle',
                    title: 'Ver todos os Sorteios',
                    soon: false,
                },
            ],
        },
        {
            to: '',
            title: 'CAMPANHA',
            subBtn: [
                {
                    to: '/admin/campaigns/new-campaign',
                    title: 'Nova campanha',
                    soon: false,
                },
                {
                    to: '/admin/exchange',
                    title: t('sidebar.subOption.mechanics.exchanges'),
                    soon: false,
                },
                {
                    to: '/admin/add-points',
                    title: t('sidebar.subOption.mechanics.addPoints'),
                    soon: false,
                },
                {
                    to: '/admin/campaigns',
                    title: 'Ver todas Campanhas',
                    soon: false,
                },
            ],
        },
        {
            to: '/admin/users',
            title: t('sidebar.option.users'),
        },
        {
            to: '',
            title: 'CONFIGURAÇÕES',
            subBtn: [
                {
                    to: '/admin/stores',
                    title: t('sidebar.subOption.mechanics.stores'),
                    soon: false,
                },
                {
                    to: '/admin/theme',
                    title: t('sidebar.subOption.mechanics.theme'),
                    soon: false,
                },
            ],
        },
    ];
    const exchangerLinks = [
        {
            to: '',
            title: 'CAMPANHA',
            subBtn: [
                {
                    to: '/admin/exchange',
                    title: t('sidebar.subOption.mechanics.exchanges'),
                    soon: false,
                },
            ],
        },
    ];
    const links = (data === null || data === void 0 ? void 0 : data.group) === GROUPS.EXCHANGER ? exchangerLinks : adminLinks;
    return (_jsx(Stack, Object.assign({}, rest, { children: _jsx(Styled.SidebarBody, { children: _jsx(Styled.LinkList, { children: links.map((btn) => (_jsx(SidebarButton, { to: btn.to, title: btn.title, subBtn: btn.subBtn }, btn.title))) }) }) })));
}
export default Sidebar;
